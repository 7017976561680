import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        if (typeof window !== 'undefined')
            window.location.replace('https://play.google.com/store/apps/details?id=com.cuenca.cuenca_mobile&hl=es');
    }, []);

    return null;
};
